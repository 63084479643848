import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import { Link, Element } from 'react-scroll';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Crousel from './Crousel';
import Section from './Section';
import ProductRange from './ProductRange';
import ProductSection from './ProductSection';
import CollectionSection from './CollectionsSection';
import LoyaltyProgram from './LoyaltyProgram';
import { LocationContext } from './LocationContext';
import Footer from './Footer';
import LoadingDots from './LoadingDots'; // Import the LoadingDots component

Modal.setAppElement('#root'); // Set the app root element for accessibility

const Home = () => {
  const { setCountry } = useContext(LocationContext);
  const [loading, setLoading] = useState(false); // Initialize as false and update based on sessionStorage
  const [showFirstPopup, setShowFirstPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [showThanksModal, setShowThanksModal] = useState(false);

  useEffect(() => {
    // Check if the loading dots have already been shown in this session
    const hasPageLoadedBefore = sessionStorage.getItem('hasPageLoaded');

    if (!hasPageLoadedBefore) {
      setLoading(true); // Show the loading dots on the first visit
      sessionStorage.setItem('hasPageLoaded', 'true'); // Store that the loading dots have been shown
    }

    // Fetch user location and simulate a loading delay
    const fetchUserLocation = async () => {
      try {
        const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-get-location');
        const data = await response.json();
        if (data.status === '1') {
          const userCountry = data.details.country;
          setCountry(userCountry);
        }
      } catch (error) {
        console.error('Error fetching user location:', error);
      }
    };

    fetchUserLocation();

    // Simulate a 2.5-second loading delay if loading dots are shown
    if (loading) {
      const loadingTimer = setTimeout(() => {
        setLoading(false); // Hide loading dots after delay
      }, 2500);

      return () => clearTimeout(loadingTimer);
    }
  }, [loading, setCountry]);

  // Popup logic
  useEffect(() => {
    const hasFirstPopupBeenDisplayed = sessionStorage.getItem('hasFirstPopupBeenDisplayed');

    const showFirstPopupDelayed = () => {
      setShowFirstPopup(true);
      sessionStorage.setItem('hasFirstPopupBeenDisplayed', 'true');
    };

    if (!hasFirstPopupBeenDisplayed) {
      setTimeout(showFirstPopupDelayed, 5000); // Show the first popup after 5 seconds
    }
  }, []);

  const handleFirstPopupAccept = () => {
    setShowFirstPopup(false);
    setShowSecondPopup(true);
  };

  const handleSecondPopupClose = () => {
    setShowSecondPopup(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    try {
      const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/user-subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          email: email,
        }).toString(),
      });

      const result = await response.json();

      if (result.status === '1') {
        console.log('Subscribed with email:', email);
        setShowSecondPopup(false);
        setShowThanksModal(true);
      } else {
        console.error('Error subscribing:', result.message);
      }
    } catch (error) {
      console.error('Error subscribing:', error.message);
    }
  };

  const closeThanksModal = () => {
    setShowThanksModal(false);
  };

  if (loading) {
    return <LoadingDots />; // Show loading animation only if it's the first visit
  }

  return (
    <>
      <Navbar />
      <Sidebar />
      <Element name="home">
        <Crousel />
      </Element>
      <Element name="productSection" style={{ backgroundColor: 'white' }}>
        <ProductSection />
      </Element>
      <Element name="section1">
        <Section />
      </Element>
      <Element name="section2">
      </Element>

      <Element name="productRange">
        <ProductRange />
      </Element>
      <Element name="loyaltyProgram" style={{ backgroundColor: 'white' }}>
        <LoyaltyProgram />
      </Element>
      <Element name="footer">
        <Footer />
      </Element>

      {/* First Popup */}
      {showFirstPopup && (
        <motion.div className="popup-container">
          <motion.div className="popup-content">
            <h1>Privacy Settings</h1>
            <p>
              We and our partners use technologies (e.g. Cookies) on these pages. The data is used for analysis and marketing purposes, e.g. to show you suitable advertising on other pages. You can accept it by clicking on "Agree" . You can adjust your selection at any time. If you do not want to agree, click on decline . Even more transparency in our data protection information.
            </p>
            <button className="accept-button" onClick={handleFirstPopupAccept}>
              Accept
            </button>
          </motion.div>
        </motion.div>
      )}

      {/* Second Popup */}
      {showSecondPopup && (
        <motion.div className="popup-container">
          <div className="discount-circle">
            <p>10% off</p>
          </div>
          <motion.div className="popup-content">
            <h1>Never miss any trends and promotions</h1>
            <p>
              Get a 10% discount with your next purchase for your first newsletter registration! As part of your registration, you are consenting to the receipt of regular personalised product recommendations by e-mail. Information about personalisation, the use of your data and unsubscribing options can be found in the detailed newsletter consent information and the data protection information.
            </p>
            <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" />
            <button className="subscribe-button" onClick={handleSubscribe}>
              Subscribe
            </button>
            <button className="close-button" onClick={handleSecondPopupClose}>
              Close
            </button>
          </motion.div>
        </motion.div>
      )}

      {/* Thanks Modal */}
      {showThanksModal && (
        <motion.div className="popup-container">
          <div className="discount-circle">
            <p>10% off</p>
          </div>
          <motion.div className="popup-content">
            <h1>Thank You for Subscribing!</h1>
            <p>
              We have sent you an email with confirmation. You will now receive updates and promotional coupons in your inbox.
            </p>
            <button onClick={closeThanksModal} style={{ color: 'white', backgroundColor: 'black', border: 'none', fontSize: '19px' }}>
              Close Window
            </button>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default Home;
