import React, { useState, useEffect } from 'react';
import img from '../img/collage sleepwear.png'; // Assuming you have this image in the specified path
import { Link } from 'react-router-dom';

const ProductRange = () => {
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    fetchSubCategories();
  }, []);

  const fetchSubCategories = async () => {
    try {
      const response = await fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/get-sub-categories');
      const data = await response.json();
      if (data.status === "1") {
        setSubCategories(data.details.slice(0, 5)); // Display only 5 subcategories
      } else {
        console.error('Error fetching sub-categories:', data.message);
      }
    } catch (error) {
      console.error('Error fetching sub-categories:', error);
    }
  };

  const descriptions = {
    'Button Down Pj Set': "The cotton blended shirts, with its crisp lines and whipstitch details, sets the stage for luxurious slumber. The long pants drape effortlessly, a symphony of comfort and style. Imagine yourself curled up with a book, this timeless duo is a night worthy masterpiece.",
    'Capri Set': "The Karen Peach top and capris set brings a hint of coolness to your wardrobe. It's crafted from lightweight cotton blends to ensure premium comfort and is cut for a non-restrictive fit that's perfect for everyday styling.",
    'Shorts Set': "When it comes to relaxed staples, we never fail to deliver the perfect lineup with designs such as this short set. Made from delicately soft fabrics, t-shirt and matching shorts are a dream to lounge in.",
    'Pyjama Sets': "Indulge in the softness and comfort of our pajama sets, designed to make your sleep experience unforgettable. Treat yourself to a restful night's sleep and waking up feeling refreshed and rejuvenated in our cozy pajama sets.",
    'Co-ord Set': "We've rounded up the best of loungewear to meet the cozy, comfy and chic essentials made for more than just lazy days. Co-ordinated sets are perfect for days on-the-go, nights in and beyond, these classic pants and easy tees are redefining lounging.",
    'Track Suit': "Something missing from your winter wardrobe? From your hoodies to your zippers, we've got you covered!Karen Peach is giving a personal touch to your winter comfort wears with beautiful graphic prints.",
    'Sweatshirt': "Tracksuits for women are in such elegant designs that are crafted to support every body type, style, and workout requirement.You don’t need to look far and beyond to update your lounging appearance."
  };

  const sectionContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px'
  };

  const imageStyle = {
    width: '100%',
    objectFit: 'cover'
  };

  const contentContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '20px',
    width: '100%'
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  };

  const buttonStyle = {
    marginTop: '5vh',
    top: '0 !important'
  };

  return (
    <>


      <div className="container mt-5">
        <div className="product-heading text-center">
          <h2>Our Product Range</h2>
        </div>

        {subCategories.map((subCategory, index) => (
          <div className="row" key={index} style={{ marginBottom: '40px' }}>
            <div className={`col-md-6 ${index % 2 === 0 ? 'order-1 order-md-1' : 'order-1 order-md-2'}`} style={contentContainerStyle}>
              <img src={subCategory.image} alt={`SubCategory Image ${index + 1}`} className="img-fluid" style={imageStyle} />
            </div>
            <div className={`col-md-6 ${index % 2 === 0 ? 'order-2 order-md-2' : 'order-2 order-md-1'}`} style={contentContainerStyle}>
              <div style={contentStyle}>
                <h1 style={{ fontWeight: 'bolder', color: '#A67B73' }}>{subCategory.subcategory}</h1>
                <p>{descriptions[subCategory.subcategory] || "Explore our collection of high-quality products."}</p>
                <p>{subCategory.description}</p>
                <Link to={`/category/${subCategory.categoryId}/${subCategory.id}`}>
                  <button className="card-button c-button exbutton" style={{ position: 'relative', width: '32vw', top: '0', backgroundColor: '#A67B73' }}>
                    Shop Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        @media (max-width: 768px) {
          .order-1 {
            order: 1 !important;
          }
          .order-2 {
            order: 2 !important;
          }
        }
      `}</style>
    </>
  );
};

export default ProductRange;
