import React, { useState, useEffect } from 'react';
import '../css/style.css';
import '../css/navbar.css';
import '../css/footer.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Modal, Box, Input, Button, InputAdornment, Drawer, List, ListItem, ListItemText, ListItemIcon, Collapse, useMediaQuery } from '@mui/material';
import logo from './KP SVG-cropped.svg';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useCart } from './CartContext';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useWishlist } from './WishlistContext';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

const Navbar = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width: 1024px)');
  const [menuData, setMenuData] = useState(null);
  const { wishlist } = useWishlist();
  const { cart } = useCart();
  const { isLoggedIn, logout } = useAuth();
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedSubcategory, setExpandedSubcategory] = useState(null);
  const [lastScrollY, setLastScrollY] = useState(0); // Track last scroll position
  const [isNavbarVisible, setNavbarVisible] = useState(true); // Track navbar visibility

  useEffect(() => {
    fetch('https://sharmasoftwaresolutions.com/karen_peach/index.php/get-home-page-header')
      .then(response => response.json())
      .then(data => {
        if (data.status === '1') {
          setMenuData(data.details.navbar.womens);
        } else {
          console.error('Error fetching menu data:', data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching menu data:', error);
      });
  }, []);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY && currentScrollY > 100) {
      // If scrolling down and scroll position is greater than 100, hide navbar
      setNavbarVisible(false);
    } else if (currentScrollY < lastScrollY) {
      // If scrolling up, show the navbar
      setNavbarVisible(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const handleSearchQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    fetchSearchSuggestions(query);
  };

  const fetchSearchSuggestions = debounce((query) => {
    if (query) {
      fetch(`https://sharmasoftwaresolutions.com/karen_peach/index.php/search-product?search=${query}`)
        .then(response => response.json())
        .then(data => {
          if (data.status === '1') {
            const suggestions = [...data.details.search_by_category, ...data.details.search_by_product];
            setSearchSuggestions(suggestions);
            setDropdownVisible(true);
          } else {
            setSearchSuggestions([]);
            setDropdownVisible(false);
          }
        })
        .catch(error => {
          console.error('Error fetching search suggestions:', error);
        });
    } else {
      setSearchSuggestions([]);
      setDropdownVisible(false);
    }
  }, 300);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/productcard?query=${searchQuery}`);
    setSearchVisible(false);
    setDropdownVisible(false);
  };

  const handleSuggestionClick = (suggestion) => {
    const query = suggestion.name || suggestion.category;
    setSearchQuery(query);
    navigate(`/productcard?query=${query}`);
    setSearchVisible(false);
    setDropdownVisible(false);
  };

  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
    setDropdownVisible(false);
  };

  const handleMenuClick = (categoryId, subcategoryId) => {
    window.location.href = `/category/${categoryId}/${subcategoryId}`;
  };

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleCategoryExpand = (category) => {
    if (expandedCategory === category) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(category);
    }
  };

  const handleSubcategoryExpand = (subcategory) => {
    if (expandedSubcategory === subcategory) {
      setExpandedSubcategory(null);
    } else {
      setExpandedSubcategory(subcategory);
    }
  };
  return (
    <>
    <nav className={`navbar navbar-expand-lg navbar-light bg-light sticky-top ${isNavbarVisible ? 'navbar-visible' : 'navbar-hidden'}`}>
      <button className="navbar-toggler" type="button" onClick={toggleDrawer}>
        <MenuIcon />
      </button>
      <Link to="/">
        <img src={logo} height="40px" alt="" />
      </Link>
      <ul className="navbar-nav ml-auto inline-links small-screen flex-row">
        <li className="nav-item" style={{ marginRight: '-12px' }}>
          <SearchIcon onClick={toggleSearch} />
        </li>
        <li className="nav-item">
          <a className="nav-link ml-1 position-relative" href="/cart">
            <LocalMallIcon fontSize="medium" style={{ color: 'black' }} />
            {cart.length > 0 && (
              <span className="badge badge-danger position-absolute top-0 end-0 translate-middle">
                {cart.length}
              </span>
            )}
          </a>
        </li>
        {!isSmallScreen && (
          <li className="nav-item dropdown" style={{ marginRight: '-12px' }}>
            <a
              className="nav-link ml-1 dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <AccountCircleIcon fontSize="medium" style={{ color: 'black' }} />
            </a>
            {isLoggedIn ? (
              <div className="dropdown-content" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/myprofile">
                  <PersonAddIcon /> My Profile
                </Link>
                <Link className="dropdown-item" to="/orderspage">
                  <LocalMallIcon /> My Orders
                </Link>
                <button className="dropdown-item" onClick={logout}>
                  <ExitToAppIcon /> Logout
                </button>
              </div>
            ) : (
              <div className="dropdown-content" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to="/signup">
                  <PersonAddIcon /> Register
                </Link>
                <Link className="dropdown-item" to="/login">
                  <ExitToAppIcon /> Login
                </Link>
              </div>
            )}
          </li>
        )}
      </ul>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto">
          {menuData &&
            menuData.map((category, index) =>
              category.subacategories.map((subcategory, i) => (
                <li className="nav-item" key={`${index}-${i}`}>
                  <a
                    className="nav-link"
                    href="#"
                    onClick={() => handleMenuClick(category.id, subcategory.id)}
                    style={{color:'black',fontFamily: 'Montserrat'}}
                  >
                    {subcategory.subcategory}
                  </a>
                </li>
              ))
            )}
        </ul>
        <ul className="navbar-nav ml-auto inline-links large-screen">
          <li className="nav-item">
            <form onSubmit={handleSearchSubmit} style={{ width: '80%' }}>
              <Input
                readOnly
                type="text"
                className="form-control"
                placeholder="Search..."
                aria-label="Search"
                aria-describedby="search-addon"
                style={{ border: 'none', outline: 'none' }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: 'gray' }} />
                  </InputAdornment>
                }
                onClick={toggleSearch}
              />
            </form>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Wishlist">
              <FavoriteIcon style={{ fontSize: "21px", marginTop: '3px', color: 'black' }} />
              {wishlist.length > 0 && (
                <span className="badge badge-danger position-absolute top-0 end-0 translate-middle">
                  {wishlist.length}
                </span>
              )}
            </Link>
          </li>
          <li className="nav-item">
            <a className="nav-link ml-1 position-relative" href="/cart">
              <LocalMallIcon fontSize="medium" style={{ color: 'black' }} />
              {cart.length > 0 && (
                <span className="badge badge-danger position-absolute top-0 end-0 translate-middle">
                  {cart.length}
                </span>
              )}
            </a>
          </li>
          {!isSmallScreen && (
            <li className="nav-item dropdown">
              <a
                className="nav-link ml-1 dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <AccountCircleIcon fontSize="medium" style={{ color: 'black' }} />
              </a>
              {isLoggedIn ? (
                <div className="dropdown-content" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/myprofile">
                    <PersonAddIcon /> My Profile
                  </Link>
                  <Link className="dropdown-item" to="/orderspage">
                    <LocalMallIcon /> My Orders
                  </Link>
                  <button className="dropdown-item" onClick={logout}>
                    <ExitToAppIcon /> Logout
                  </button>
                </div>
              ) : (
                <div className="dropdown-content" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/signup">
                    <PersonAddIcon /> Register
                  </Link>
                  <Link className="dropdown-item" to="/login">
                    <ExitToAppIcon /> Login
                  </Link>
                </div>
              )}
            </li>
          )}
        </ul>
      </div>
    </nav>

      <Drawer
  anchor="left"
  open={isDrawerOpen}
  onClose={toggleDrawer}
  className="mobile-drawer"
  sx={{ width: '80%', '& .MuiDrawer-paper': { width: '80%', bgcolor: '#f7f7f7' } }} // Adjust the width and background color here
>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 3,
      bgcolor: 'background.paper',
    }}
  >
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <img src={logo} height="50px" alt="Logo" />
      <CloseIcon onClick={toggleDrawer} sx={{ cursor: 'pointer' }} />
    </Box>
    <List sx={{ width: '100%' }}>
      {menuData &&
        menuData.map((category, index) => (
          <React.Fragment key={index}>
            <ListItem button onClick={() => handleCategoryExpand(category.category)} sx={{ borderBottom: '1px solid #ccc' }}>
              <ListItemText primary={category.category} />
              {expandedCategory === category.category ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expandedCategory === category.category} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: 2 }}>
                {category.subacategories.map((subcategory, i) => (
                  <ListItem button key={i} onClick={() => handleMenuClick(category.id, subcategory.id)} sx={{ borderBottom: '1px solid #ccc' }}>
                    <ListItemText primary={subcategory.subcategory} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
      <ListItem button component={Link} to="/wishlist" sx={{ borderBottom: '1px solid #ccc' }}>
        <ListItemIcon>
          <FavoriteIcon />
        </ListItemIcon>
        <ListItemText primary="Wishlist" />
      </ListItem>
      <ListItem  button
  component="a"
  href="/cart"
  sx={{ borderBottom: '1px solid #ccc' }}>
        <ListItemIcon>
          <LocalMallIcon />
        </ListItemIcon>
        <ListItemText primary="Cart" />
      </ListItem>
      {isLoggedIn ? (
        <>
          <ListItem button component={Link} to="/myprofile" sx={{ borderBottom: '1px solid #ccc' }}>
            <ListItemIcon>
              <LocalMallIcon />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItem>
          <ListItem button component={Link} to="/orderspage" sx={{ borderBottom: '1px solid #ccc' }}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="My Orders" />
          </ListItem>
          <ListItem button onClick={logout} sx={{ borderBottom: '1px solid #ccc' }}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </>
      ) : (
        <>
          <ListItem button component={Link} to="/signup" sx={{ borderBottom: '1px solid #ccc' }}>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary="Register" />
          </ListItem>
          <ListItem button component={Link} to="/login" sx={{ borderBottom: '1px solid #ccc' }}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
        </>
      )}
    </List>
  </Box>
</Drawer>


      {isSearchVisible && (
        <Modal open={isSearchVisible} onClose={toggleSearch} style={{ position: 'fixed', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height: '20%',
            bgcolor: 'background.paper',
            p: 2,
          }}
        >
          <Link to="/">
            <img src={logo} height="50px" alt="" />
          </Link>
          <form onSubmit={handleSearchSubmit} style={{ width: '80%' }}>
            <Input
              type="text"
              className="form-control"
              placeholder="Search..."
              aria-label="Search"
              aria-describedby="search-addon"
              style={{ border: 'none', outline: 'none', paddingLeft: '2px', width: '70%' }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: 'gray' }} />
                </InputAdornment>
              }
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
            {isDropdownVisible && (
              <div className="search-suggestions-dropdown">
                <ul>
                  {searchSuggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                      {suggestion.name || suggestion.category}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </form>
          <Button onClick={toggleSearch} style={{ marginLeft: '10px', color: 'black' }}>
            <CloseIcon />
          </Button>
        </Box>
      </Modal>
      )}
    </>
  );
};

export default Navbar;
